import { Table } from 'react-bootstrap';
import CompliancyRow from './CompliancyRow';

function CompliancyList({ title, list }) {
	return <div className="col-12 col-lg-6">
		<div className="rounded shadow-sm bg-white mb-2 p-4">
			<h1>{title}</h1>
			<Table striped>
				<thead>
					<tr>
						<th>Training</th>
						<th>Employee</th>
						<th>Completed on</th>
						<th>Days remaining</th>
					</tr>
				</thead>
				<tbody>
					{list?.map((entry, index) => <CompliancyRow key={index} {...entry}/>)}
				</tbody>
			</Table>
		</div>
	</div>;
}

export default CompliancyList;
