import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { validateAccount } from '../../validation';
import Alert from '../../components/alert/Alert';
import AccountForm from '../../components/account/AccountForm';
import api from '../../api';
import Title from '../../components/Title';

export default function UpdateAccount() {
	const [oldAccount, setOldAccount] = useState(null);
	const [error, setError] = useState(null);
	const { username } = useParams();

	useEffect(() => {
		api.account.getAccount(username)
			.then(setOldAccount)
			.catch(setError);
	}, []);

	function handleUpdate(account) {
		return api.account.updateAccount(account);
	}

	return <Container>
		<Title text="Edit account" />
		{error && <Alert message={error} />}
		<FormWrapper
			defaultValues={oldAccount}
			validate={validateAccount(true)}
			submit={handleUpdate}
			render={(...params) => AccountForm(...params, true)}
		/>
	</Container>;
}
