import { Button, Form, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { loadFunctions, loadTrainings, getDate } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PersonForm(values, setEventValues, handleSetValues, functions=[], trainings=[]) {
	function addTraining(newTraining) {
		const trainingIndex = values.trainings.findIndex((training) => {
			return training.value === newTraining.value;
		});
		if(trainingIndex === -1) {
			handleSetValues('trainings', [...values.trainings, newTraining]);
		}
	}
	const removeTraining = (id) => () => {
		const trainingIndex = values.trainings.findIndex((training) => {
			return training.value === id;
		});
		handleSetValues('trainings', values.trainings.toSpliced(trainingIndex, 1));
	};
	return <div className="row">
		<div className="col">
			<Form.Group className="mb-2">
				<Form.Label>First name*</Form.Label>
				<Form.Control
					name="firstName"
					value={values.firstName}
					type="text"
					placeholder="first name"
					onChange={setEventValues}
					required
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Label>Last name*</Form.Label>
				<Form.Control
					name="lastName"
					value={values.lastName}
					type="text"
					placeholder="last name"
					onChange={setEventValues}
					required
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Label>Email*</Form.Label>
				<Form.Control
					name="email"
					value={values.email}
					type="email"
					placeholder="email"
					onChange={setEventValues}
					required
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Label>Start date of employment*</Form.Label>
				<Form.Control
					name="startDate"
					value={getDate(values.startDate)}
					type="date"
					onChange={setEventValues}
					required
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Label>End date of employment (optional)</Form.Label>
				<Form.Control
					name="endDate"
					value={getDate(values.endDate)}
					type="date"
					onChange={setEventValues}
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Label>Date of birth*</Form.Label>
				<Form.Control
					name="dateOfBirth"
					value={getDate(values.dateOfBirth)}
					type="date"
					onChange={setEventValues}
					required
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Label>Function*</Form.Label>
				<AsyncSelect
					value={values.function}
					loadOptions={loadFunctions}
					onChange={(value) => handleSetValues('function', value)}
					defaultOptions={functions}
				/>
			</Form.Group>
		</div>
		<div className="col d-flex">
			<Form.Group className="d-flex flex-column flex-grow-1 mb-2">
				<Form.Label>Trainings*</Form.Label>
				<AsyncSelect
					value={{label: 'Select a training', value: ''}}
					loadOptions={loadTrainings}
					onChange={addTraining}
					defaultOptions={trainings}
				/>
				<div className="position-relative flex-grow-1 w-100" style={{ overflowY: 'scroll' }}>
					<div className="position-absolute w-100 pe-2">
						{values.trainings.map(({ value, label }) => <InputGroup key={value} className="mt-3">
							<Form.Control
								name="tr"
								value={label}
								type="text"
								disabled
							/>
							<Button className="z-index-0" variant="danger" onClick={removeTraining(value)}>
								<FontAwesomeIcon icon="xmark" size="lg"/>
							</Button>
						</InputGroup>)}
					</div>
				</div>
			</Form.Group>
		</div>
	</div>;
}
