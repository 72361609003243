import { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '../hooks/account';
import api from '../api';
import Spinner from '../components/Spinner';

function NavLink({ link, children }) {
	let className = 'text-decoration-none me-4 my-2 ';
	if(useLocation().pathname.startsWith(link)) {
		className = className + 'text-white';
	} else {
		className = className + 'text-secondary';
	}
	return <Link to={link} className={className}> {children} </Link>;
}

export default function Header() {
	const [loading, setLoading] = useState();
	const { logout, account, hasRole } = useAccount();
	function handleLogout() {
		setLoading(true);
		api.auth.logout()
			.then(logout)
			.catch(() => {
				console.error('Failed to logout');
				setLoading(false);
			});
	}
	return <Navbar bg="dark" data-bs-theme="dark" expand="lg">
		<Container className="py-2">
			<NavLink link="/home">
				<img src={logo} alt='logo' style={{height: '2rem'}}/>
			</NavLink>
			<Navbar.Toggle aria-controls="basic-navbar-nav"/>
			<div className="clickable d-block d-lg-none" onClick={handleLogout}>
				{!loading && <>
					<span className="text-white">Logout</span>
					<FontAwesomeIcon className="ms-2" icon="right-from-bracket" color='white'/>
				</>}
				{loading && <Spinner color="white" size="lg"/>}
			</div>
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav>
					<NavLink link="/user">{account.username}</NavLink>
					{hasRole('readCompletions') && <NavLink link="/completions">Completions</NavLink>}
					{hasRole('readPersons') && <NavLink link="/persons">Persons</NavLink>}
					{hasRole('readTrainings') && <NavLink link="/trainings">Trainings</NavLink>}
					{hasRole('readFunctions') && <NavLink link="/functions">Functions</NavLink>}
					{hasRole('readAccounts') && <NavLink link="/accounts">Accounts</NavLink>}
				</Nav>
			</Navbar.Collapse>
			<div className="clickable d-none d-lg-block" onClick={handleLogout}>
				{!loading && <>
					<span className="text-white">Logout</span>
					<FontAwesomeIcon className="ms-2" icon="right-from-bracket" color='white'/>
				</>}
				{loading && <Spinner color="white" size="lg"/>}
			</div>
		</Container>
	</Navbar>;
}
