import { Container } from 'react-bootstrap';
import { useAccount } from '../hooks/account';
import api from '../api';
import PasswordForm from '../components/user/PasswordForm';

export default function User() {
	const { account } = useAccount();

	function submitNewPassword(newPasswords) {
		return api.account.updatePassword(newPasswords);
	}
	return (
		<Container>
			<h1 className="my-4">{account.username}</h1>
			<PasswordForm submit={submitNewPassword}/>
		</Container>
	);
}
