import React, { useState } from 'react';
import HttpStatus from 'http-status-codes';
import LoginForm from '../components/LoginForm';
import API from '../api';

import Message from '../messages/Message';

import { canSignIn } from '../storage';

import { useAccount } from '../hooks/account';

const lastErrorMessage = canSignIn ? null : Message.create('Please enable cookies to sign in');

export default function Login() {
	const [details, setDetails] = useState({ username: '', password: '' });
	const [state, setState] = useState({ submitButtonDisabled: false, lastErrorMessage});
	const { login } = useAccount();

	const handleSubmit = (e) => {
		e.preventDefault();
		setState({
			submitButtonDisabled: true,
			lastErrorMessage: null,
		});
		API.auth.login(details.username, details.password).then((res) => {
			login(res.data);
		}).catch(err => {
			let message;
			if(err.status === HttpStatus.UNAUTHORIZED) {
				message = 'Invalid username or password';
			} else {
				message = 'Unknown error';
			}
			setState({
				submitButtonDisabled: false,
				lastErrorMessage: Message.danger(message),
			});
		});
	};

	function handleSet(e) {
		setDetails(prev => ({ ...prev, [e.target.name]: e.target.value }));
	}

	return (
		<LoginForm
			onSubmit={handleSubmit}
			setField={handleSet}
			{...details}
			{...state}
		/>
	);
}
