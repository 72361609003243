import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import PersonForm from '../../components/person/PersonForm';
import { validatePerson } from '../../validation';
import { listToOptions } from '../../utils';
import api from '../../api';
import Title from '../../components/Title';

const defaultPerson = {
	firstName: '',
	lastName: '',
	email: '',
	startDate: '',
	endDate: '',
	dateOfBirth: '',
	function: '',
	trainings: []
};

export default function CreatePerson() {
	const [functions, setFunctions] = useState(undefined);
	const [trainings, setTrainings] = useState(undefined);
	const [error, setError] = useState(undefined);
	const navigate = useNavigate();

	useEffect(() => {
		Promise.all([
			api.function.getFunctions({ max: 50 }).then(listToOptions).then(setFunctions),
			api.training.getTrainings({ max: 50 }).then(listToOptions).then(setTrainings)
		]).catch(setError);
	}, []);

	function handleCreate(person) {
		return api.person.createPerson({
			...person,
			endDate: person.endDate || null,
			function: person.function.value,
			trainings: person.trainings.map(({ value }) => value)
		}).then(() => {
			navigate('/persons');
		});
	}

	return <Container>
		<Title text="Create person" />
		{error && <Alert message={error} />}
		<FormWrapper
			type="Create"
			defaultValues={defaultPerson}
			validate={validatePerson}
			submit={handleCreate}
			render={(...params) => PersonForm(
				...params,
				functions,
				trainings
			)}
		/>
	</Container>;
}
