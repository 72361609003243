import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import CompletionTable from '../../components/completion/CompletionTable';
import useQuery from '../../hooks/useQuery';
import { useAccount } from '../../hooks/account';
import Pagination from '../../components/Pagination';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import CompletionFilter from '../../components/completion/CompletionFilter';
import FilterWrapper from '../../components/FilterWrapper';
import api from '../../api';

const defaultInput = {
	searchTerm: '',
	from: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
	to: ''
};

export default function Completions() {
	const [completionData, setCompletionData] = useState(null);
	const [error, setError] = useState(null);
	const { hasRole } = useAccount();
	const [params] = useQuery();

	function getCompletions() {
		setCompletionData(null);
		return api.completion.getCompletions({
			...params,
			from: params.from || defaultInput.from,
			max: 50
		})
			.then(setCompletionData)
			.catch(setError);
	}

	useEffect(() => {
		getCompletions();
	}, [params]);

	function handleDelete(id) {
		return api.completion.deleteCompletion(id).then(getCompletions);
	}

	return <Container>
		<div className="d-flex flex-row align-items-center justify-content-between">
			<h1 className="my-4">Completions</h1>
			{hasRole('writeCompletions') && <Link className="btn btn-primary" to="/completion/create">
					Create Completion
				<FontAwesomeIcon className="ms-2" icon="plus"/>
			</Link>}
		</div>
		<FilterWrapper
			defaultInput={defaultInput}
			render={CompletionFilter}
			disabled={!completionData}
		/>
		<CompletionTable
			completions={completionData?.list}
			deleteCompletion={handleDelete}
			error={error}
		/>
		<Pagination pages={completionData?.pages}/>
	</Container>;
}
