import { Form } from 'react-bootstrap';

function TrainingFilter(values, handleSetEvent) {
	return <Form.Group className="col">
		<Form.Control
			name="searchTerm"
			value={values.searchTerm}
			type="text"
			placeholder="..."
			onChange={handleSetEvent}
		/>
	</Form.Group>;
}

export default TrainingFilter;
