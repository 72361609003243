import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AsyncSelect from 'react-select/async';
import ValidityText from './ValidityText';
import { loadPersons } from '../../utils';

export default function TrainingForm(values, setEventValues, handleSetValues, persons=[]) {
	function addPerson(newPerson) {
		const personIndex = values.persons.findIndex((person) => {
			return person.value === newPerson.value;
		});
		if(personIndex === -1) {
			handleSetValues('persons', [...values.persons, newPerson]);
		}
	}
	const removePerson = (id) => () => {
		const personIndex = values.persons.findIndex((person) => {
			return person.value === id;
		});
		handleSetValues('persons', values.persons.toSpliced(personIndex, 1));
	};
	return <>
		<Form.Group className="mb-2">
			<Form.Label>name</Form.Label>
			<Form.Control
				name="name"
				value={values.name}
				type="text"
				placeholder="training name"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>link</Form.Label>
			<Form.Control
				name="link"
				value={values.link}
				type="url"
				placeholder="link to the training"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>validity amount</Form.Label>
			<Form.Control
				name="validityAmount"
				value={values.validityAmount}
				type="number"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>validity time</Form.Label>
			<Form.Select
				name="validityTime"
				value={values.validityTime}
				onChange={setEventValues}
			>
				<option value="">Select a time</option>
				<option value="day">days</option>
				<option value="week">weeks</option>
				<option value="month">months</option>
				<option value="year">years</option>
			</Form.Select>
		</Form.Group>
		<Form.Group className="mb-4">
			<ValidityText {...values}/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>Persons</Form.Label>
			<AsyncSelect
				value={{label: 'Select a person', value: ''}}
				loadOptions={loadPersons}
				onChange={addPerson}
				defaultOptions={persons}
			/>
			{values.persons.map(({ value, label }) => <InputGroup key={value} className="mt-3">
				<Form.Control
					name="tr"
					value={label}
					type="text"
					disabled
				/>
				<Button className="z-index-0" variant="danger" onClick={removePerson(value)}>
					<FontAwesomeIcon icon="xmark" size="lg"/>
				</Button>
			</InputGroup>)}
		</Form.Group>
	</>;
}
