import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function TableControlButtons({ hasRole, editTo, onDelete }) {
	if(hasRole) {
		return <>
			<td className="button-col">
				<Link className="btn btn-primary btn-sm" variant="primary" size="sm" to={editTo}>
					Edit
				</Link>
			</td>
			<td className="button-col">
				<Button variant="danger" size="sm" onClick={onDelete}>
					Delete
				</Button>
			</td>
		</>;
	}
}
