// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client, { getData } from '../client';


export default {
	createPolicy(policy) {
		return client.post('/policy', policy);
	},
	getPolicies(query) {
		return client.get('/policies', { params: query }).then(getData);
	},
	getPolicy(id) {
		return client.get(`/policy/${encodeURIComponent(id)}`).then(getData);
	},
	updatePolicy(id, newPolicy) {
		return client.put(`/policy/${encodeURIComponent(id)}`, newPolicy);
	},
	deletePolicy(id) {
		return client.delete(`/policy/${encodeURIComponent(id)}`);
	}
};
