import { Form, Button, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { loadPersons, loadTrainings, getDate } from '../../utils';
import FileDrop from '../FileDrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CompletionForm(values, setEventValues, handleSetValues, persons=[], trainings=[]) {
	return <>
		<Form.Group className="mb-2">
			<Form.Label>Person*</Form.Label>
			<AsyncSelect
				value={values.person}
				loadOptions={loadPersons}
				onChange={(value) => handleSetValues('person', value)}
				defaultOptions={persons}
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>Training*</Form.Label>
			<AsyncSelect
				value={values.training}
				loadOptions={loadTrainings}
				onChange={(value) => handleSetValues('training', value)}
				defaultOptions={trainings}
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>Date*</Form.Label>
			<Form.Control
				name="date"
				value={getDate(values.date)}
				type="date"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>Score</Form.Label>
			<Form.Control
				name="score"
				value={values.score}
				type="text"
				placeholder="score"
				onChange={setEventValues}
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label>
				File
				{values.savedFile && <>
					<br/>
					current file: <b>{values.savedFile}</b>
				</>}
			</Form.Label>
			{!values.file && <FileDrop onSetFile={(file) => handleSetValues('file', file)}/>}
			{values.file && <InputGroup className="mb-3">
				<Form.Control
					type="text"
					value={values.file.name}
					disabled
				/>
				<Button variant="danger" onClick={() => handleSetValues('file', null)}>
					<FontAwesomeIcon icon="xmark" size="lg"/>
				</Button>
			</InputGroup>}
		</Form.Group>
	</>;
}
