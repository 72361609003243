import React, { useState } from 'react';
import { Form, FormLabel } from 'react-bootstrap';

function FileDrop({ onSetFile }) {
	const [dragActive, setDragActive] = useState(false);
	function handleDragOVer(e) {
		e.stopPropagation();
		e.preventDefault();
	}
	function activate() {
		setDragActive(true);
	}
	function deactivate() {
		setDragActive(false);
	}
	function handleDrop(e) {
		e.preventDefault();
		e.stopPropagation();
		onSetFile(e.dataTransfer.files?.[0]);
		deactivate();
	}
	function handleInput(e) {
		onSetFile(e.target.files?.[0]);
	}
	return <div
		className="d-flex flex-column align-items-center justify-content-center mb-2 file-drop text-center"
		onDragOver={handleDragOVer}
		onDragEnter={activate}
	>
		{!dragActive && <>
			<h5>Drag and drop a file here<br/> or </h5>
			<FormLabel className="m-0">
				<span className="btn btn-primary">Select a file</span>
				<Form.Control hidden type="file" accept=".csv,text/csv" onChange={handleInput}/>
			</FormLabel>
		</>}
		{dragActive && <div className="file-release d-flex flex-column justify-content-center h4" onDragLeave={deactivate} onDrop={handleDrop}>
			Release to select file
		</div>}
	</div>;
}

export default FileDrop;
