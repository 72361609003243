import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import AccountForm from '../../components/account/AccountForm';
import { validateAccount } from '../../validation';
import api from '../../api';
import Title from '../../components/Title';

const defaultAccount = {
	username: '',
	password: '',
	active: true,
	roles: []
};

export default function CreateAccount() {
	const navigate = useNavigate();

	function handleCreate(newAccount) {
		return api.account.updateAccount(newAccount).then(() => {
			navigate('/accounts');
		});
	}

	return <Container>
		<Title text="Create account" />
		<FormWrapper
			type="Create"
			defaultValues={defaultAccount}
			validate={validateAccount(false)}
			submit={handleCreate}
			render={AccountForm}
		/>
	</Container>;
}
