import { useState } from 'react';
import { formatDate } from '../../utils';
import { Button, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URL } from '../../api/client';
import Spinner from '../Spinner';

const MIME_TYPES = {
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
	'application/vnd.oasis.opendocument.text': 'odt',
	'application/vnd.oasis.opendocument.presentation': 'odp',
	'application/vnd.oasis.opendocument.spreadsheet': 'ods',
	'application/pdf': 'pdf'
};

function FileRow({ file, openDeleteModal, searched, searchedHit, updateDescription }) {
	const [description, setDescription] = useState('');
	const [updating, setUpdating] = useState(false);
	const [editing, setEditing] = useState(false);

	function handleSubmit(e) {
		if(e.code === 'Enter') {
			updateDescription(description)
				.then(() => {
					setUpdating(false);
					setEditing(false);
				})
				.catch(() => {
					setUpdating(false);
				});
		}
		if(e.code === 'Escape') {
			setEditing(false);
		}
	}

	function openEdit() {
		setDescription(file.description || '');
		setEditing(true);
	}

	return <tr>
		<td className="button-col">
			<a
				className="btn btn-sm btn-primary"
				href={`${URL}/person/file/${encodeURIComponent(file.id)}`}
				download={file.name}
			>
				<FontAwesomeIcon icon="download" size="lg"/>
			</a>
		</td>
		<td>{file.name}</td>
		{searched && <td>
			{searchedHit && <FontAwesomeIcon className="mt-1" icon="magnifying-glass" color="black" size="xl"/>}
		</td>}
		{!updating && <>
			{!editing && <td className="user-select-none clickable" onClick={openEdit}>
				{file.description}
				<FontAwesomeIcon className="ms-2" icon="pen" color="black"/>
			</td>}
			{editing && <td onBlur={() => setEditing(false)}>
				<FormControl
					type="text"
					value={description}
					onKeyDown={handleSubmit}
					onChange={(e) => setDescription(e.target.value)}
				/>
			</td>}
		</>}
		{updating && <td><Spinner size="lg"/></td>}
		<td>{formatDate(file.date)}</td>
		<td>{MIME_TYPES[file.type]}</td>
		<td className="button-col">
			<Button variant='danger' onClick={() => openDeleteModal({ id: file._id, name: file.name })} size="sm">
				<FontAwesomeIcon icon="xmark" size="lg"/>
			</Button>
		</td>
	</tr>;
}

export default FileRow;
