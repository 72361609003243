// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client, { getData } from '../client';

export default {
	createFunction(func) {
		return client.post('/function', func);
	},
	getFunctions(query) {
		return client.get('/functions', { params: query }).then(getData);
	},
	getFunction(id) {
		return client.get(`/function/${encodeURIComponent(id)}`).then(getData);
	},
	updateFunction(id, newFunction) {
		return client.put(`/function/${encodeURIComponent(id)}`, newFunction);
	},
	deleteFunction(id) {
		return client.delete(`/function/${encodeURIComponent(id)}`);
	}
};
