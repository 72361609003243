import { Table } from 'react-bootstrap';
import { useAccount } from '../../hooks/account';
import TableWrapper from '../TableWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableControlButtons from '../TableControlButtons';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';

function PersonCompletions({ deleteCompletion, completions }) {
	const { hasRole } = useAccount();
	const canWriteCompletions = hasRole('writeCompletions');
	return <div className="position-relative bg-white rounded shadow-sm p-4 mb-4">
		<div className="d-flex flex-row align-items-center justify-content-between">
			<h4>Completions</h4>
			{canWriteCompletions && <Link className="btn btn-primary" to="/completion/create">
				Create Completion
				<FontAwesomeIcon className="ms-2" icon="plus"/>
			</Link>}
		</div>
		<TableWrapper
			className=""
			deleteCB={deleteCompletion}
			render={(handleDelete) => <>
				<Table striped>
					<thead>
						<tr>
							<th>Training</th>
							<th>Date</th>
							<th>Score</th>
							<th>File</th>
							{canWriteCompletions && <>
								<th/>
								<th/>
							</>}
						</tr>
					</thead>
					<tbody>
						{completions?.map(c => <tr key={c._id}>
							<td>{c.training}</td>
							<td>{c.date}</td>
							<td>{c.score}</td>
							{c.file && <td>
								<a
									className="btn btn-sm btn-primary"
									href={`${URL}/completion/file/${encodeURIComponent(c.file.id)}`}
									download={c.file.name}
								>Download<FontAwesomeIcon className="ms-2" icon="download"/>
								</a>
							</td>}
							{!c.file && <td>{c.score}</td>}
							<TableControlButtons
								hasRole={canWriteCompletions}
								editTo={`/completion/${encodeURIComponent(c._id)}`}
								onDelete={handleDelete({ id: c._id, name: c.training })}
							/>
						</tr>)}
					</tbody>
				</Table>
				{!completions && <Spinner size="lg"/>}
			</>}
		/>
	</div>;
}

export default PersonCompletions;
