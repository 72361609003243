import { Table } from 'react-bootstrap';
import Spinner from '../Spinner';
import TableWrapper from '../TableWrapper';
import { useAccount } from '../../hooks/account';
import ValidityText from './ValidityText';
import TableControlButtons from '../TableControlButtons';
import THSort from '../THSort';

export default function TrainingTable({ trainings, deleteTraining, error }) {
	const { hasRole } = useAccount();
	const canWriteTrainings = hasRole('writePersons');
	return <TableWrapper
		error={error}
		deleteCB={deleteTraining}
		render={(handleDelete) => <>
			<Table striped>
				<thead>
					<tr>
						<THSort value="name" defaultSort>Name</THSort>
						<THSort value="link">Link</THSort>
						<th>Validity</th>
						<th>Persons</th>
						{canWriteTrainings && <>
							<th/>
							<th/>
						</>}
					</tr>
				</thead>
				<tbody>
					{trainings?.map(t => <tr key={t._id}>
						<td>{t.name}</td>
						<td><a href={t.link} target="_blank">{t.link}</a></td>
						<td><ValidityText {...t} short/></td>
						<td>{t.persons}</td>
						<TableControlButtons
							hasRole={canWriteTrainings}
							editTo={`/training/${encodeURIComponent(t._id)}`}
							onDelete={handleDelete({ id: t._id, name: t.name })}
						/>
					</tr>)}
				</tbody>
			</Table>
			{!trainings && <Spinner size="lg"/>}
		</>}
	/>;
}
