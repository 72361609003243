import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountTable from '../../components/account/AccountTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import { useAccount } from '../../hooks/account';

export default function Accounts() {
	const [accountData, setAccountData] = useState(null);
	const [error, setError] = useState(null);
	const { hasRole } = useAccount();

	async function getAccounts() {
		setAccountData(null);
		await api.account.getAccounts({ max: 500 })
			.then(setAccountData)
			.catch(setError);
	}

	useEffect(() => {
		getAccounts();
	}, []);

	function handleDelete(username) {
		return api.account.deleteAccount(username).then(getAccounts);
	}

	return (
		<Container>
			<div className="d-flex flex-row align-items-center justify-content-between">
				<h1 className="my-4">Account</h1>
				{hasRole('writeAccounts') && <Link className="btn btn-primary" to="/account/create">
					Create Account
					<FontAwesomeIcon className="ms-2" icon="plus"/>
				</Link>}
			</div>
			<AccountTable
				accounts={accountData?.list}
				deleteAccount={handleDelete}
				error={error}
			/>
		</Container>
	);
}
