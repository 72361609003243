import { Container } from 'react-bootstrap';
import TrainingForm from '../../components/training/TrainingForm';
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { validateTraining } from '../../validation';
import { useEffect, useState } from 'react';
import { listToOptions } from '../../utils';
import Title from '../../components/Title';
import api from '../../api';

const defaultTraining = {
	name: '',
	link: '',
	validityAmount: '0',
	validityTime: '',
	persons: []
};

export default function CreateTraining() {
	const [persons, setPersons] = useState(undefined);
	const [error, setError] = useState(undefined);
	const navigate = useNavigate();

	useEffect(() => {
		api.person.getPersons({ max: 50 })
			.then(listToOptions)
			.then(setPersons)
			.catch(setError);
	}, []);

	function handleCreate(training) {
		return api.training.createTraining({
			...training,
			persons: training.persons.map(({value}) => value)
		}).then(() => {
			navigate('/trainings');
		});
	}

	return <Container>
		<Title text="Create training" />
		{error && <Alert message={error} />}
		<FormWrapper
			type="Create"
			defaultValues={defaultTraining}
			validate={validateTraining}
			submit={handleCreate}
			render={(...params) => TrainingForm(
				...params,
				persons
			)}
		/>
	</Container>;
}
