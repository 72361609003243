function FlowButton({ title, active, onClick }) {
	const color = active? 'white' : 'secondary';
	let className = 'user-select-none switch bg-' + color;
	if(!active) {
		className += ' clickable';
	}
	return <div className={className} onClick={onClick}>
		<div className={`flow flow-left bg-${color}`}/>
		<div className="flow flow-left cover-left"/>
		<h5 className="m-0">{title}</h5>
		<div className={`flow flow-right bg-${color}`}/>
		<div className="flow flow-right cover-right"/>
		{active && <div className="shadow-blocker"/>}
	</div>;
}

export default FlowButton;
