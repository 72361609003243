import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { validateFunction } from '../../validation';
import Alert from '../../components/alert/Alert';
import FunctionForm from '../../components/function/FunctionForm';
import api from '../../api';
import Title from '../../components/Title';

export default function UpdateFunction() {
	const [oldFunction, setOldFunction,] = useState(null);
	const [error, setError] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		api.function.getFunction(id).then(setOldFunction).catch(setError);
	}, []);

	function handleUpdate(func) {
		return api.function.updateFunction(id, func);
	}

	return <Container>
		<Title text="Edit function" />
		{error && <Alert message={error} />}
		<FormWrapper
			defaultValues={oldFunction}
			validate={validateFunction}
			submit={handleUpdate}
			render={FunctionForm}
		/>
	</Container>;
}
