import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TrainingTable from '../../components/training/TrainingTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import api from '../../api';
import { useAccount } from '../../hooks/account';
import Pagination from '../../components/Pagination';
import FilterWrapper from '../../components/FilterWrapper';
import TrainingFilter from '../../components/training/TrainingFilter';

const defaultInput = {
	searchTerm: ''
};

export default function Trainings() {
	const [trainingData, setTrainingData] = useState(null);
	const [error, setError] = useState(null);
	const { hasRole } = useAccount();
	const [params] = useQuery();

	async function getTrainings() {
		setTrainingData(null);
		await api.training.getTrainings({ ...params, max: 50})
			.then(setTrainingData)
			.catch(setError);
	}

	useEffect(() => {
		getTrainings();
	}, [params]);

	function handleDelete(id) {
		return api.training.deleteTraining(id).then(getTrainings);
	}

	return (
		<Container>
			<div className="d-flex flex-row align-items-center justify-content-between">
				<h1 className="my-4">Trainings</h1>
				{hasRole('writeTrainings') && <Link className="btn btn-primary" to="/training/create">
					Create Training
					<FontAwesomeIcon className="ms-2" icon="plus"/>
				</Link>}
			</div>
			<FilterWrapper
				defaultInput={defaultInput}
				render={TrainingFilter}
			/>
			<TrainingTable
				trainings={trainingData?.list}
				deleteTraining={handleDelete}
				error={error}
			/>
			<Pagination pages={trainingData?.pages}/>
		</Container>
	);
}
