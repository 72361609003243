import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { validateCompletion } from '../../validation';
import Alert from '../../components/alert/Alert';
import { listToOptions } from '../../utils';
import api from '../../api';
import CompletionForm from '../../components/completion/CompletionForm';
import Title from '../../components/Title';

export default function UpdatePerson() {
	const [persons, setPersons] = useState(undefined);
	const [trainings, setTrainings] = useState(undefined);
	const [oldCompletion, setOldCompletion] = useState(null);
	const [error, setError] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		api.completion.getCompletion(id).then(({ file, ...c }) => {
			c.savedFile = file?.name;
			setOldCompletion(c);
		}).catch(setError);
		api.person.getPersons({ max: 50 }).then(listToOptions).then(setPersons).catch(setError);
		api.training.getTrainings({ max: 50 }).then(listToOptions).then(setTrainings).catch(setError);
	}, []);

	function handleUpdate({ person, training, date, score, file }, setValues) {
		const data = new FormData();
		data.append('person', person.value);
		data.append('training', training.value);
		data.append('date', date);
		if(score) {
			data.append('score', score);
		}
		if(file) {
			data.append('file', file);
		}
		return api.completion.updateCompletion(id, data).then(() => {
			setValues(old => ({
				...old,
				savedFile: file?.name || old.savedFile,
				file: null
			}));
		});
	}

	return <Container>
		<Title text="Edit completion"/>
		{error && <Alert message={error} />}
		<FormWrapper
			defaultValues={oldCompletion}
			validate={validateCompletion}
			submit={handleUpdate}
			render={(...params) => CompletionForm(
				...params,
				persons,
				trainings
			)}
		/>
	</Container>;
}
