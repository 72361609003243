import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export function useGoBack() {
	const navigate = useNavigate();
	return () => navigate(-1);
}

export function BackButton(props) {
	return <Button variant="primary" onClick={useGoBack()} {...props}>Back</Button>;
}
