import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { validatePerson } from '../../validation';
import Alert from '../../components/alert/Alert';
import PersonForm from '../../components/person/PersonForm';
import { listToOptions } from '../../utils';
import PersonFiles from '../../components/person/PersonFiles';
import api from '../../api';
import SwitchButtons from '../../components/person/SwitchButtons';
import '../../css/updatePerson.css';
import PersonCompletions from '../../components/person/PersonCompletions';
import useQuery from '../../hooks/useQuery';
import Title from '../../components/Title';

export default function UpdatePerson() {
	const [functions, setFunctions] = useState(undefined);
	const [trainings, setTrainings] = useState(undefined);
	const [person, setPerson] = useState(null);
	const [error, setError] = useState(null);
	const [{ tab='files' }, setParams] = useQuery();
	const { id } = useParams();

	function getPerson() {
		return api.person.getPerson(id).then(setPerson).catch(setError);
	}

	useEffect(() => {
		getPerson();
		api.function.getFunctions({ max: 50 }).then(listToOptions).then(setFunctions).catch(setError);
		api.training.getTrainings({ max: 50 }).then(listToOptions).then(setTrainings).catch(setError);
	}, []);

	function handleUpdate(newPerson) {
		return api.person.updatePerson(id, {
			...newPerson,
			endDate: newPerson.endDate || null,
			function: newPerson.function.value,
			trainings: newPerson.trainings.map(tr => tr.value)
		});
	}

	function uploadFile(file) {
		if(file) {
			const data = new FormData();
			data.append('file', file);
			return api.person.uploadFile(id, data).then(getPerson);
		}
	}

	function deleteFile(fileId) {
		return api.person.deleteFile(id, fileId).then(getPerson);
	}

	function updateDescription(fileId, description) {
		return api.person.updateDescription(fileId, description).then(getPerson);
	}

	const handleChange = (newTab) => () => {
		setParams({ tab: newTab });
	};

	function handleDelete(completionId) {
		return api.completion.deleteCompletion(completionId).then(getPerson);
	}

	return <>
		<Container>
			<Title text={`${person?.firstName || ''} ${person?.lastName || ''}`}/>
			{error && <Alert message={error} />}
			{person && <FormWrapper
				defaultValues={person}
				validate={validatePerson}
				submit={handleUpdate}
				render={(...params) => PersonForm(...params, functions, trainings)}
			/>}
		</Container>
		{person && <Container fluid="xxl">
			<SwitchButtons active={tab} change={handleChange}/>
			{tab === 'files' && <PersonFiles
				files={person.files}
				uploadFile={uploadFile}
				deleteFile={deleteFile}
				updateDescription={updateDescription}
			/>}
			{tab === 'completions' && <PersonCompletions
				completions={person.completions}
				deleteCompletion={handleDelete}
			/>}
		</Container>}
	</>;
}
