import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TrainingForm from '../../components/training/TrainingForm';
import { useParams } from 'react-router-dom';
import api from '../../api';
import FormWrapper from '../../components/FormWrapper';
import { validateTraining } from '../../validation';
import Alert from '../../components/alert/Alert';
import { listToOptions } from '../../utils';
import Title from '../../components/Title';

export default function UpdateTraining() {
	const [oldTraining, setOldTraining] = useState(undefined);
	const [persons, setPersons] = useState(undefined);
	const [error, setError] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		api.training.getTraining(id).then(setOldTraining).catch(setError);
		api.person.getPersons({ max: 50 })
			.then(listToOptions)
			.then(setPersons)
			.catch(setError);
	}, []);

	function handleUpdate(training) {
		return api.training.updateTraining(id, {
			...training,
			persons: training.persons.map(({ value }) => value)
		});
	}

	return <Container>
		<Title text="Edit training"/>
		{error && <Alert message={error} />}
		<FormWrapper
			defaultValues={oldTraining}
			validate={validateTraining}
			submit={handleUpdate}
			render={(...params) => TrainingForm(
				...params,
				persons
			)}
		/>
	</Container>;
}
