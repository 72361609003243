import { format } from 'date-fns';
import api from './api';

const SEARCH_DELAY = 1000;

function debounceAsync(callb) {
	let timeout;
	return (searchTerm) => new Promise((resolve, reject) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			callb(searchTerm).then(resolve).catch(reject);
		}, SEARCH_DELAY);
	});
}

export function listToOptions({ list }) {
	return list.map((x) => ({ label: x.name, value: x._id }));
}

export const loadPersons = debounceAsync((searchTerm) => {
	return api.person.getPersons({ searchTerm, max: 50 }).then(listToOptions);
});

export const loadTrainings = debounceAsync((searchTerm) => {
	return api.training.getTrainings({ searchTerm, max: 50 }).then(listToOptions);
});

export const loadFunctions = debounceAsync((searchTerm) => {
	return api.function.getFunctions({ searchTerm, max: 50 }).then(listToOptions);
});

export function getDate(str) {
	if(str) {
		return format(new Date(str), 'yyyy-MM-dd');
	}
	return '';
}

export function formatDate(date) {
	return format(new Date(date), 'MM-dd-yyyy');
}
