import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PersonTable from '../../components/person/PersonTable';
import useQuery from '../../hooks/useQuery';
import { useAccount } from '../../hooks/account';
import Pagination from '../../components/Pagination';
import FilterWrapper from '../../components/FilterWrapper';
import api from '../../api';
import PersonFilter from '../../components/person/PersonFilter';

const defaultInput = {
	searchTerm: '',
	from: '',
	to: '',
	ex: false,
	text: ''
};

export default function Persons() {
	const [personData, setPersonData] = useState(null);
	const [error, setError] = useState(null);
	const { hasRole } = useAccount();
	const [params] = useQuery();

	function getPersons() {
		setPersonData(null);
		return api.person.getPersons({ ...params, max: 50 })
			.then(setPersonData)
			.catch(setError);
	}

	useEffect(() => {
		getPersons();
	}, [params]);

	function handleDelete(id) {
		return api.person.deletePerson(id).then(getPersons);
	}

	return <Container>
		<div className="d-flex flex-row align-items-center justify-content-between">
			<h1 className="my-4">Persons</h1>
			{hasRole('writePersons') && <Link className="btn btn-primary" to="/person/create">
				Create Person
				<FontAwesomeIcon className="ms-2" icon="plus"/>
			</Link>}
		</div>
		<FilterWrapper
			defaultInput={defaultInput}
			render={PersonFilter}
			disabled={!personData}
		/>
		<PersonTable
			persons={personData?.list}
			deletePerson={handleDelete}
			text={params.text}
			error={error}
		/>
		<Pagination pages={personData?.pages}/>
	</Container>;
}
