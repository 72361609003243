import FlowButton from './FlowButton';

function SwitchButtons({ active, change }) {
	return <div className="d-flex flex-row justify-content-around mt-4">
		<FlowButton
			title="Files"
			active={active === 'files'}
			onClick={change('files')}
		/>
		<FlowButton
			title="Completions"
			active={active === 'completions'}
			onClick={change('completions')}
		/>
	</div>;
}

export default SwitchButtons;
