import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSort from '../hooks/useSort';

function THSort({ children, value, defaultSort=false, ...props }) {
	const [sort, direction, setSort] = useSort();
	return <th
		className="clickable user-select-none"
		onClick={setSort(value)}
		{...props}
	>
		{children}
		{(!sort && defaultSort || sort === value) && <FontAwesomeIcon
			className="ms-2"
			icon={
				direction === '1'?
					'sort-up'
					:
					'sort-down'
			}
		/>}
	</th>;
}

export default THSort;
