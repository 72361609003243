import { Form } from 'react-bootstrap';

export default function FunctionForm(values, setEventValues) {
	return <>
		<Form.Group className="mb-2">
			<Form.Label>Function name</Form.Label>
			<Form.Control
				name="name"
				value={values.name}
				type="text"
				placeholder="function name"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
	</>;
}
