import { Form } from 'react-bootstrap';

function CompletionFilter(values, handleSetEvent) {
	return <>
		<Form.Group className="col-12 col-lg">
			<Form.Label>Search</Form.Label>
			<Form.Control
				name="searchTerm"
				value={values.searchTerm}
				type="text"
				placeholder="..."
				onChange={handleSetEvent}
			/>
		</Form.Group>
		<div className="my-2 d-block d-lg-none"/>
		<Form.Group className="col col-lg-2">
			<Form.Label>From</Form.Label>
			<Form.Control
				name="from"
				value={values.from}
				type="date"
				onChange={handleSetEvent}
			/>
		</Form.Group>
		<Form.Group className="col col-lg-2">
			<Form.Label>To</Form.Label>
			<Form.Control
				name="to"
				value={values.to}
				type="date"
				onChange={handleSetEvent}
			/>
		</Form.Group>
	</>;
}

export default CompletionFilter;
