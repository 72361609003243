import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FunctionTable from '../../components/function/FunctionTable';
import { Link } from 'react-router-dom';
import api from '../../api';
import { useAccount } from '../../hooks/account';

export default function Functions() {
	const [functionData, setFunctionData] = useState(null);
	const [error, setError] = useState(null);
	const { hasRole } = useAccount();

	async function getFunctions() {
		await api.function.getFunctions({ max: 500 })
			.then(setFunctionData)
			.catch(setError);
	}

	useEffect(() => {
		getFunctions();
	}, []);

	function handleDelete(id) {
		return api.function.deleteFunction(id).then(getFunctions);
	}

	return (
		<Container>
			<div className="d-flex flex-row align-items-center justify-content-between">
				<h1 className="my-4">Functions</h1>
				{hasRole('writeFunctions') && <Link className="btn btn-primary" to="/function/create">
					Create Function
					<FontAwesomeIcon className="ms-2" icon="plus"/>
				</Link>}
			</div>
			<FunctionTable
				functions={functionData?.list}
				deleteFunction={handleDelete}
				error={error} />
		</Container>
	);
}
