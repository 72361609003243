// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client, { getData } from '../client';

export default {
	createCompletion(completion) {
		return client.post('/completion', completion);
	},
	getCompletions(query) {
		return client.get('/completions', { params: query }).then(getData);
	},
	getCompletion(id) {
		return client.get(`/completion/${encodeURIComponent(id)}`).then(getData);
	},
	updateCompletion(id, newCompletion) {
		return client.put(`/completion/${encodeURIComponent(id)}`, newCompletion);
	},
	deleteCompletion(id) {
		return client.delete(`/completion/${encodeURIComponent(id)}`);
	}
};
