import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CompliancyRow({ personName, trainingName, date, remaining }) {
	return <tr className="border-bottom mb-2">
		<td>{trainingName}</td>
		<td>{personName}</td>
		{date && <>
			<td>{date}</td>
			<td>{remaining}</td>
		</>}
		{!date && <td md="6" colSpan={2}>
			not done
			<FontAwesomeIcon className="ms-2" icon="triangle-exclamation" color="red" size="lg"/>
		</td>}
	</tr>;
}

export default CompliancyRow;
