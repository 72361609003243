import './App.css';
import Router from './containers/Router';
import LoaderContainer from './containers/Loader';
import Header from './containers/Header';
import { useAccount } from './hooks/account';


function App() {
	const { loggedIn, logout } = useAccount();
	return (
		<main>
			{loggedIn && <Header logout={logout}/>}
			<LoaderContainer />
			<Router />
		</main>
	);
}

export default App;
