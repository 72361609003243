// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client, { getData } from '../client';

export default {
	createPerson(person) {
		return client.post('/person', person);
	},
	getPersons(query) {
		return client.get('/persons', { params: query }).then(getData);
	},
	getPerson(id) {
		return client.get(`/person/${encodeURIComponent(id)}`).then(getData);
	},
	updatePerson(id, newPerson) {
		return client.put(`/person/${encodeURIComponent(id)}`, newPerson);
	},
	deletePerson(id) {
		return client.delete(`/person/${encodeURIComponent(id)}`);
	},
	uploadFile(id, data) {
		return client.post(`/person/${encodeURIComponent(id)}/file`, data);
	},
	deleteFile(id, fileId) {
		return client.delete(`/person/${encodeURIComponent(id)}/file/${encodeURIComponent(fileId)}`);
	},
	updateDescription(id, description) {
		return client.put(`/person/file/${encodeURIComponent(id)}/description`, { description });
	},
};
