import { Table } from 'react-bootstrap';
import Spinner from '../Spinner';
import TableWrapper from '../TableWrapper';
import CheckBox from '../CheckBox';
import TableControlButtons from '../TableControlButtons';
import { useAccount } from '../../hooks/account';
import { formatDate } from '../../utils';

export default function AccountTable({ accounts, deleteAccount, error }) {
	const { hasRole } = useAccount();
	const canWriteAccount = hasRole('writeAccount');
	return <TableWrapper
		error={error}
		deleteCB={deleteAccount}
		render={(handleDelete) => <>
			<Table striped>
				<thead>
					<tr>
						<th>Username</th>
						<th>Active</th>
						<th>Created</th>
						{canWriteAccount && <>
							<th/>
							<th/>
						</>}
					</tr>
				</thead>
				<tbody>
					{accounts?.map(acc => <tr key={acc._id}>
						<td>{acc.username}</td>
						<td><CheckBox checked /></td>
						<td>{formatDate(acc.created)}</td>
						<TableControlButtons
							hasRole={canWriteAccount}
							editTo={`/account/${encodeURIComponent(acc.username)}`}
							onDelete={handleDelete({ id: acc.username, name: acc.username })}
						/>
					</tr>)}
				</tbody>
			</Table>
			{!accounts && <Spinner size="lg"/>}
		</>}
	/>;
}
