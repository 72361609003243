import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import Spinner from './Spinner';
import Alert from './alert/Alert';

function DeleteModal({ toDelete, close, deleteCB, ...props}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	if(!toDelete?.name) {
		return null;
	}
	function handleCallback() {
		setError(false);
		setLoading(true);
		deleteCB(toDelete?.id)
			.then(close)
			.catch(setError)
			.finally(() => {
				setLoading(false);
			});
	}
	return (
		<Modal show {...props}>
			<ModalHeader closeButton>
				<Modal.Title>Delete "{toDelete?.name}"</Modal.Title>
			</ModalHeader>
			<ModalBody>
				Are you sure you want to delete "<b>{toDelete?.name}</b>"?
				{error && <Alert message={error}/>}
			</ModalBody>
			<ModalFooter className="d-flex flex-row justify-content-between">
				{!loading && <>
					<Button variant='danger' onClick={handleCallback}>Delete</Button>
					<Button variant='primary' onClick={close}>Cancel</Button>
				</>}
				{loading && <Spinner color="black"/>}
			</ModalFooter>
		</Modal>
	);
}

export default DeleteModal;
