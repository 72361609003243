import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function useQuery() {
	const [params, setParams] = useSearchParams();
	const result = useMemo( () => {
		const values = {};
		params.forEach((value, key) => {
			values[key] = value;
		});
		return values;
	}, [params]);
	return [result, setParams];
}

export default useQuery;
