import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useQuery from '../hooks/useQuery';

const ONE = 1;
const TWO = 2;

function Pagination({ pages }) {
	const [params, setParams] = useQuery();

	if(!pages || pages <= ONE) {
		return null;
	}

	let currentPage = 1;
	if(params.page) {
		currentPage = +params.page;
	}

	const setPage = (newPage) => () => {
		setParams({
			...params,
			page: newPage
		});
	};

	return <div className="d-flex flex-row justify-content-center mt-2">
		{currentPage > ONE && <Button className="me-3" onClick={setPage(currentPage - ONE)}>
			<FontAwesomeIcon icon="arrow-left"/>
		</Button>}
		{currentPage > TWO && <Button className="mx-1 fs-5" onClick={setPage(currentPage - TWO)}>
			{currentPage - TWO}
		</Button>}
		{currentPage > ONE && <Button className="mx-1 fs-5" onClick={setPage(currentPage - ONE)}>
			{currentPage - ONE}
		</Button>}
		<Button className="mx-1 fs-5" disabled>
			<u>{currentPage || ONE}</u>
		</Button>
		{pages > currentPage && <Button className="mx-1 fs-5" onClick={setPage(currentPage + ONE)}>
			{currentPage + ONE}
		</Button>}
		{pages > currentPage + ONE && <Button className="mx-1 fs-5" onClick={setPage(currentPage + TWO)}>
			{currentPage + TWO}
		</Button>}
		{pages > currentPage && <Button className="ms-3" onClick={setPage(currentPage + ONE)}>
			<FontAwesomeIcon icon="arrow-right"/>
		</Button>}
	</div>;
}

export default Pagination;
