//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons/faSquareCheck';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';

const icons = [
	faRightFromBracket,
	faSpinner,
	faPlus,
	faMagnifyingGlass,
	faSquareCheck,
	faSquare,
	faXmark,
	faTriangleExclamation,
	faSortUp,
	faSortDown,
	faArrowRight,
	faArrowLeft,
	faDownload,
	faPen
];

export default function() {
	library.add(...icons);
}
