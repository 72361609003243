import useQuery from './useQuery';

export default function useSort() {
	const [params, setParams] = useQuery();

	const setSort = (newSort) => () => {
		let newDirection = '1';
		if(
			params.sort === newSort &&
			params.direction === '1'
		) {
			newDirection = '-1';
		}
		setParams({
			...params,
			sort: newSort,
			direction: newDirection
		});
	};

	return [params.sort, params.direction, setSort];
}
