import { VALIDITY_AMOUNT_MAX } from '../../validation';

export default function ValidityText({ validityAmount, validityTime, short=false }) {
	if(validityTime === '') {
		return <span>
			<b>Invalid</b> validity time
		</span>;
	}
	if(
		isNaN(+validityAmount) ||
		+validityAmount > VALIDITY_AMOUNT_MAX
	) {
		return <span>
			<b>Invalid</b> validity amount
		</span>;
	}
	const amountNumber = +validityAmount;
	return <span>
		{!short && 'Training valid '}
		{amountNumber === 0 && <b>
			forever
		</b>}
		{amountNumber !== 0 && <>
			{!short && 'for '}
			<b>{amountNumber} {validityTime}{amountNumber > 1 && 's'}</b>
		</>}
	</span>;
}
