import { Table } from 'react-bootstrap';
import Spinner from '../Spinner';
import TableWrapper from '../TableWrapper';
import TableControlButtons from '../TableControlButtons';
import { useAccount } from '../../hooks/account';

export default function FunctionTable({ functions, deleteFunction, error }) {
	const { hasRole } = useAccount();
	const canWriteFunctions = hasRole('writeFunctions');
	return <TableWrapper
		error={error}
		deleteCB={deleteFunction}
		render={(handleDelete) => <>
			<Table striped>
				<thead>
					<tr>
						<th>Function</th>
						{canWriteFunctions && <>
							<th/>
							<th/>
						</>}
					</tr>
				</thead>
				<tbody>
					{functions?.map(f => <tr key={f._id}>
						<td>{f.name}</td>
						<TableControlButtons
							hasRole={canWriteFunctions}
							editTo={`/function/${encodeURIComponent(f._id)}`}
							onDelete={handleDelete({ id: f._id, name: f.name })}
						/>
					</tr>)}
				</tbody>
			</Table>
			{!functions && <Spinner size="lg"/>}</>}
	/>;
}
