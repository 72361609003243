//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import { session } from '../storage';

const STORAGE_KEY = 'flash_messages';

function parseStorage() {
	const out = {};
	try {
		const messages = session.getItem(STORAGE_KEY);
		if(messages) {
			const json = JSON.parse(messages);
			for(const key in json) {
				const { message, data } = json[key];
				out[key] = { message, data };
			}
		}
	} catch(ignored) { /**/ }
	return out;
}

const messages = parseStorage();

function save() {
	session.setItem(STORAGE_KEY, JSON.stringify(messages));
}

function set(name, message, data) {
	messages[name] = { message, data };
	save();
}

function remove(name) {
	delete messages[name];
	save();
}

function get(name) {
	const message = messages[name];
	remove(name);
	return message || null;
}

export default {
	get, set
};
