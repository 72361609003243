import { useEffect } from 'react';

import API from '../api';
import { useAccount } from '../hooks/account';

export default function LoaderContainer() {
	const { login, logout, doneChecking } = useAccount();
	useEffect(() => {
		API.auth.checkLogin()
			.then(res => {
				login(res.data);
			})
			.catch(logout)
			.finally(doneChecking);
	}, []);
	return false;
}
