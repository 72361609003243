import React, { useState, useEffect } from 'react';
import { Alert as BSAlert } from 'react-bootstrap';

export default function Alert({ message, variant='danger', ...props }) {
	const [open, setOpen] = useState(true);
	useEffect(() => {
		setOpen(true);
	}, [message]);
	return <BSAlert className="mt-2" {...props} variant={variant} show={open} onClose={() => setOpen(false)}>
		{message.message}
	</BSAlert>;
}
