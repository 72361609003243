import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import FormWrapper from '../../components/FormWrapper';
import CompletionForm from '../../components/completion/CompletionForm';
import { validateCompletion } from '../../validation';
import { listToOptions } from '../../utils';
import api from '../../api';
import { useGoBack } from '../../hooks/useGoBack';
import Title from '../../components/Title';

const defaultCompletion = {
	person: '',
	training: '',
	date: '',
	score: '',
	file: null
};

export default function CreateCompletion() {
	const [persons, setPersons] = useState(undefined);
	const [trainings, setTrainings] = useState(undefined);
	const [error, setError] = useState(undefined);
	const goBack = useGoBack();

	useEffect(() => {
		api.person.getPersons({ max: 50 }).then(listToOptions).then(setPersons).catch(setError);
		api.training.getTrainings({ max: 50 }).then(listToOptions).then(setTrainings).catch(setError);
	}, []);

	function handleCreate({ person, training, date, score, file }) {
		const data = new FormData();
		data.append('person', person.value);
		data.append('training', training.value);
		data.append('date', date);
		if(score) {
			data.append('score', score);
		}
		if(file) {
			data.append('file', file);
		}
		return api.completion.createCompletion(data).then(goBack);
	}

	return <Container>
		<Title text="Create completion"/>
		{error && <Alert message={error} />}
		<FormWrapper
			type="Create"
			defaultValues={defaultCompletion}
			validate={validateCompletion}
			submit={handleCreate}
			render={(...params) => CompletionForm(
				...params,
				persons,
				trainings
			)}
		/>
	</Container>;
}
