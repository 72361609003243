import account from './modules/account';
import auth from './modules/auth';
import person from './modules/person';
import func from './modules/function';
import training from './modules/training';
import policy from './modules/policy';
import compliancy from './modules/compliancy';
import completion from './modules/completions';

export default {
	account,
	auth,
	completion,
	compliancy,
	function: func,
	person,
	policy,
	training
};
