import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../../components/FormWrapper';
import { validateFunction } from '../../validation';
import FunctionForm from '../../components/function/FunctionForm';
import api from '../../api';
import Title from '../../components/Title';

const defaultFunction = {
	name: ''
};

export default function CreateFunction() {
	const navigate = useNavigate();

	function handleCreate(func) {
		return api.function.createFunction(func)
			.then(() => {
				navigate('/functions');
			});
	}

	return <Container>
		<Title text="Crete function" />
		<FormWrapper
			type="Create"
			defaultValues={defaultFunction}
			validate={validateFunction}
			submit={handleCreate}
			render={FunctionForm}
		/>
	</Container>;
}
