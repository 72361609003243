// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client from '../client';

const OK = 200;
const UNAUTHORIZED = 401;

export default {
	login(username, password, token) {
		return client.post('/auth/login', {username, password, token});
	},
	logout() {
		return client.post('/auth/logout', {}, {
			validateStatus: function (status) {
				return status === OK || status === UNAUTHORIZED;
			}
		});
	},
	checkLogin() {
		return client.get('/auth/me');
	}
};
