import { Button, Form } from 'react-bootstrap';
import Spinner from '../Spinner';
import { useState } from 'react';
import Alert from '../alert/Alert';

const defaultValues = {
	password: '', oldPassword: '', confirmPassword: ''
};

export default function PasswordForm({ submit }) {
	const [submitting, setSubmitting] = useState(false);
	const [values, setValues] = useState(defaultValues);
	const [error, setError] = useState(null);
	function setEventValues(e) {
		setValues(old => ({ ...old, [e.target.name]: e.target.value }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		setSubmitting(true);
		setError(false);
		submit(values)
			.then(() => setValues(defaultValues))
			.catch(setError)
			.finally(() => setSubmitting(false));
	}
	const isValid = values.password &&
	values.confirmPassword &&
	values.password === values.confirmPassword;
	return <Form onSubmit={handleSubmit}>
		<Form.Group className="mb-3">
			<Form.Label>Old password</Form.Label>
			<Form.Control
				name="oldPassword"
				value={values.oldPassword}
				type="text"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-3">
			<Form.Label>New password</Form.Label>
			<Form.Control
				name="password"
				value={values.password}
				type="text"
				onChange={setEventValues}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-3">
			<Form.Label>Confirm new password</Form.Label>
			<Form.Control
				name="confirmPassword"
				value={values.confirmPassword}
				type="text"
				onChange={setEventValues}
				isInvalid={!isValid}
				isValid={isValid}
				required
			/>
		</Form.Group>
		{error && <Alert message={error}/>}
		{submitting && <Spinner />}
		{!submitting && <Button
			type="submit"
			disabled={!values.oldPassword || !values.password || !values.confirmPassword || !isValid}
		>
			Submit
		</Button>}
	</Form>;
}
