import { useState } from 'react';
import FileDrop from '../FileDrop';
import Alert from '../alert/Alert';
import Spinner from '../Spinner';
import { Table } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
import { useSearchParams } from 'react-router-dom';
import FileRow from './FileRow';

function PersonFiles({ files, uploadFile, deleteFile, updateDescription }) {
	const [toDelete, setToDelete] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(null);
	const [params] = useSearchParams();
	const queryText = params.get('text');
	const queryFiles = params.getAll('files');
	function handleUploadFile(file) {
		setError(null);
		setUploading(true);
		uploadFile(file).catch(setError).finally(() => {
			setUploading(false);
		});
	}
	const handleUpdateDescription = (id) => (description) => {
		return updateDescription(id, description).catch((err) => {
			setError(err);
			throw new Error(err);
		});
	};
	return <div className="position-relative bg-white rounded shadow-sm p-4 mb-4">
		<h4>Files</h4>
		<small>Max file size is 10MB. Allowed file types: <b>pdf, docx, pptx, xlsx, odt, odp, ods</b>.</small>
		<DeleteModal
			close={() => setToDelete(null)}
			toDelete={toDelete}
			deleteCB={deleteFile}
			size="md"
		/>
		{!uploading && <FileDrop onSetFile={handleUploadFile}/>}
		{uploading && <div className="d-flex justify-content-center mb-5">
			<Spinner size="3x"/>
		</div>}
		{error && <Alert message={error}/>}
		{queryText && <h4 className="text-center mt-4 mb-2">You searched for "<b>{queryText}</b>"</h4>}
		<Table striped>
			<thead>
				<tr>
					<th/>
					<th>Name</th>
					{queryText && <th>Searched</th>}
					<th>Description</th>
					<th>Date</th>
					<th>Type</th>
					<th/>
				</tr>
			</thead>
			<tbody>
				{files?.map((f) => <FileRow
					key={f._id}
					file={f}
					openDeleteModal={setToDelete}
					searched={queryText}
					searchedHit={queryFiles?.includes(f._id)}
					updateDescription={handleUpdateDescription(f._id)}
				/>)}
			</tbody>
		</Table>
	</div>;
}

export default PersonFiles;
