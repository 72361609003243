import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import useQuery from '../hooks/useQuery';

function FilterWrapper({ defaultInput, render, disabled=false }) {
	const [values, setValues] = useState(defaultInput);
	const [params, setParams] = useQuery();

	useEffect(() => {
		setValues({ ...defaultInput, ...params });
	}, [params]);

	function submit(e) {
		e.preventDefault();
		setParams({ ...params, ...values });
	}

	function handleSet(prop, value) {
		setValues(old => ({ ...old, [prop]: value }));
	}

	function handleSetEvent(e) {
		handleSet(e.target.name, e.target.value);
	}

	return <div className="bg-white p-4 mb-3 rounded shadow-sm">
		<Form className="row" onSubmit={submit} aria-disabled={disabled}>
			{render(values, handleSetEvent, handleSet)}
			<div className="col-auto d-flex align-items-end">
				<Button type="submit" disabled={disabled}>Submit</Button>
			</div>
		</Form>
	</div>;
}

export default FilterWrapper;
