import { Form, Table } from 'react-bootstrap';
import CheckBox from '../CheckBox';

const subjects = ['Accounts', 'Completions', 'Persons', 'Trainings', 'Functions', ];
const writeRoles = subjects.map(subject => `write${subject}`);
const readRoles = subjects.map(subject => `read${subject}`);

export default function AccountForm(values, setEventValues, handleSetValues, isEdit=false) {
	const setRole = (role, roleIndex) => () => {
		if(roleIndex === -1) {
			handleSetValues('roles', [...values.roles, role]);
		} else {
			handleSetValues('roles', values.roles.toSpliced(roleIndex, 1));
		}
	};
	const hasWriteAll = writeRoles.every(role => values.roles.includes(role));
	const hasReadAll = readRoles.every(role => values.roles.includes(role));
	const setAll = (subject) => () => {
		const hasAll = subject === 'write'? hasWriteAll : hasReadAll;
		if(hasAll) {
			handleSetValues('roles', values.roles.filter((role) => !role.startsWith(subject)));
		} else {
			const newRoles = subject === 'write'? writeRoles : readRoles;
			handleSetValues('roles', [...values.roles, ...newRoles]);
		}
	};
	return <>
		<Form.Group className="mb-3">
			<Form.Label>Username*</Form.Label>
			<Form.Control
				name="username"
				value={values.username}
				type="text"
				placeholder="username"
				onChange={setEventValues}
				disabled={isEdit}
				required
			/>
		</Form.Group>
		<Form.Group className="mb-3">
			<Form.Label>Password{isEdit && '*'}</Form.Label>
			<Form.Control
				name="password"
				value={values.password}
				type="text"
				placeholder="password"
				onChange={setEventValues}
				required={!isEdit}
			/>
		</Form.Group>
		<Form.Group className="mb-2">
			<Form.Label className="user-select-none" onClick={() => handleSetValues('active', !values.active)}>
				<CheckBox size="xl" className="pe-2" checked={!!values.active}/>
				Active*
			</Form.Label>
		</Form.Group>
		<Form.Group className="mb-2 user-select-none">
			<Table striped>
				<thead>
					<tr>
						<th>Roles</th>
						<th>Write</th>
						<th>Read</th>
					</tr>
				</thead>
				<tbody>
					{subjects.map(sub => {
						const writeIndex = values.roles.indexOf(`write${sub}`);
						const readIndex = values.roles.indexOf(`read${sub}`);
						return <tr key={sub}>
							<td>{sub}</td>
							<td>
								<CheckBox
									size="xl"
									onClick={setRole(`write${sub}`, writeIndex)}
									checked={writeIndex !== -1}
								/>
							</td>
							<td>
								<CheckBox
									size="xl"
									onClick={setRole(`read${sub}`, readIndex)}
									checked={readIndex !== -1}
								/>
							</td>
						</tr>;
					})}
					<tr>
						<th>All</th>
						<td><CheckBox size="xl" onClick={setAll('write')} checked={hasWriteAll}/></td>
						<td><CheckBox size="xl" onClick={setAll('read')} checked={hasReadAll}/></td>
					</tr>
				</tbody>
			</Table>
		</Form.Group>
	</>;
}
