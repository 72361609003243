import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './Home';
import Trainings from './training/Trainings';
import CreateTraining from './training/CreateTraining';
import UpdateTraining from './training/UpdateTraining';
import Persons from './person/Persons';
import CreatePerson from './person/CreatePerson';
import UpdatePerson from './person/UpdatePerson';
import Completions from './completion/Completions';
import CreateCompletion from './completion/CreateCompletion';
import UpdateCompletion from './completion/UpdateCompletion';
import Login from './Login';
import User from './User';
import Accounts from './account/Accounts';
import CreateAccount from './account/CreateAccount';
import UpdateAccount from './account/UpdateAccount';
import { useAccount } from '../hooks/account';
import CreateFunction from './function/CreateFunction';
import UpdateFunction from './function/UpdateFunction';
import Functions from './function/Functions';

export default function Router() {
	const { checking, loggedIn, hasRole } = useAccount();
	if(checking) {
		return null;
	}
	return <Routes>
		{loggedIn && <>
			<Route path='/home' element={<Home />} />
			<Route path='/user' element={<User />} />

			{hasRole('readTrainings') && <Route path='/trainings' element={<Trainings />}/>}
			{hasRole('writeTrainings') && <Route path='/training/create' element={<CreateTraining />}/>}
			{hasRole('writeTrainings') && <Route path='/training/:id' element={<UpdateTraining />}/>}

			{hasRole('readFunctions') && <Route path='/functions' element={<Functions />}/>}
			{hasRole('writeFunctions') && <Route path='/function/create' element={<CreateFunction />}/>}
			{hasRole('writeFunctions') && <Route path='/function/:id' element={<UpdateFunction />}/>}

			{hasRole('readPersons') && <Route path='/persons' element={<Persons />}/>}
			{hasRole('writePersons') && <Route path='/person/create' element={<CreatePerson />}/>}
			{hasRole('writePersons') && <Route path='/person/:id' element={<UpdatePerson />}/>}

			{hasRole('readCompletions') && <Route path='/completions' element={<Completions />}/>}
			{hasRole('writeCompletions') && <Route path='/completion/create' element={<CreateCompletion />}/>}
			{hasRole('writeCompletions') && <Route path='/completion/:id' element={<UpdateCompletion />}/>}

			{hasRole('readAccounts') && <Route path='/accounts' element={<Accounts />}/>}
			{hasRole('writeAccounts') && <Route path='/account/create' element={<CreateAccount />}/>}
			{hasRole('writeAccounts') && <Route path='/account/:username' element={<UpdateAccount />}/>}
			<Route path='*' element={<Navigate to="/home" />}/>
		</>}

		{!loggedIn && <>
			<Route path='/login' element={<Login />}/>
			<Route path='*' element={<Navigate to="/login" />}/>
		</>}
	</Routes>;
}
