import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row } from 'react-bootstrap';

function PersonFilter(values, handleSetEvent, handleSet) {
	let ex = 'fa-square';
	if(values.ex === true || values.ex === 'true') {
		ex = 'fa-square-check';
	}
	return <Col>
		<Row>
			<Form.Group className="col">
				<Form.Label>Search</Form.Label>
				<Form.Control
					name="searchTerm"
					value={values.searchTerm}
					type="text"
					placeholder="..."
					onChange={handleSetEvent}
				/>
			</Form.Group>
			<Form.Group className="col-2">
				<Form.Label>Started from</Form.Label>
				<Form.Control
					name="from"
					value={values.from}
					type="date"
					onChange={handleSetEvent}
				/>
			</Form.Group>
			<Form.Group className="col-2">
				<Form.Label>Started to</Form.Label>
				<Form.Control
					name="to"
					value={values.to}
					type="date"
					onChange={handleSetEvent}
				/>
			</Form.Group>
		</Row>
		<Row className="mt-4">
			<Form.Group className="col">
				<Form.Label>Search in files</Form.Label>
				<Form.Control
					name="text"
					value={values.text}
					type="text"
					placeholder="..."
					onChange={handleSetEvent}
				/>
			</Form.Group>
			<Form.Group
				className="col-auto clickable user-select-none"
				onClick={() => handleSet('ex', !(values.ex === true || values.ex === 'true'))}>
				<Form.Label>Include ex-employees</Form.Label>
				<br/>
				<FontAwesomeIcon icon={`fa-regular ${ex}`} color="black" size="2x"/>
			</Form.Group>
		</Row>
	</Col>;
}

export default PersonFilter;
