export const VALIDITY_AMOUNT_MAX = 5000;
export function validateTraining(values) {
	return values &&
	values.name &&
	values.link &&
	!isNaN(values.validityAmount) &&
	values.validityAmount !== '' &&
	+values.validityAmount <= VALIDITY_AMOUNT_MAX &&
	values.validityTime;
}

export function validatePerson(values) {
	return values &&
	values.firstName &&
	values.lastName &&
	values.email &&
	values.startDate &&
	values.dateOfBirth &&
	values.function &&
	values.trainings?.length >= 0;
}

export function validateFunction(values) {
	return values && values.name;
}

export const validateAccount = (isEdit) => (values) => {
	return values &&
	!!values.username &&
	(isEdit || !!values.password) &&
	(values.active === true || values.active === false) &&
	!!values.roles?.length >= 0;
};

export function validateCompletion(values) {
	return values &&
	!!values.person &&
	!!values.training &&
	!!values.date;
}
