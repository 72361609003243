import { URL } from '../../api/client';

function DownloadLink(props) {
	return <a className="btn btn-primary ms-2" {...props}/>;
}

export default function ExportBar({ query }) {
	const params = Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&');
	return <div className="d-flex flex-row justify-content-between rounded shadow-sm bg-white my-3 p-3">
		<h3 className="text-center m-0">Export</h3>
		<div className="d-flex flex-row">
			<DownloadLink
				href={`${URL}/compliancy/export?${params}`}
				download="all_compliancy.csv"
			>All compliancy</DownloadLink>
			<DownloadLink
				href={`${URL}/compliancy/export/compliant?${params}`}
				download="compliant_persons.csv"
			>Compliant</DownloadLink>
			<DownloadLink
				href={`${URL}/compliancy/export/noncompliant?${params}`}
				download="non_compliant_persons.csv"
			>Non-Compliant</DownloadLink>
		</div>
	</div>;
}
