import { Table } from 'react-bootstrap';
import Spinner from '../Spinner';
import TableWrapper from '../TableWrapper';
import { useAccount } from '../../hooks/account';
import TableControlButtons from '../TableControlButtons';
import THSort from '../THSort';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URL } from '../../api/client';
import { formatDate } from '../../utils';

export default function CompletionTable({ completions, deleteCompletion, error }) {
	const { hasRole } = useAccount();
	const canWriteCompletions = hasRole('writeCompletions');
	return <TableWrapper
		error={error}
		deleteCB={deleteCompletion}
		render={(handleDelete) => <>
			<Table striped>
				<thead>
					<tr>
						<th>Person</th>
						<th>Training</th>
						<THSort value="date" defaultSort>Date</THSort>
						<THSort value="score">Score</THSort>
						<th>File</th>
						{canWriteCompletions && <>
							<th/>
							<th/>
						</>}
					</tr>
				</thead>
				<tbody>
					{completions?.map(c => <tr key={c._id}>
						<td>{c.person}</td>
						<td>{c.training}</td>
						<td>{formatDate(c.date)}</td>
						<td>{c.score}</td>
						{c.file && <td>
							<a
								className="btn btn-sm btn-primary"
								href={`${URL}/completion/file/${encodeURIComponent(c.file.id)}`}
								download={c.file.name}
							>
								Download
								<FontAwesomeIcon className="ms-2" icon="download"/>
							</a>
						</td>}
						{!c.file && <td/>}
						<TableControlButtons
							hasRole={canWriteCompletions}
							editTo={`/completion/${encodeURIComponent(c._id)}`}
							onDelete={handleDelete({ id: c._id, name: `${c.training} by ${c.person}` })}
						/>
					</tr>)}
				</tbody>
			</Table>
			{!completions && <Spinner size="lg"/>}
		</>}
	/>;
}
