//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import React from 'react';
import HttpStatus from 'http-status-codes';

export default class APIError extends Error {
	constructor (data, status, next) {
		if (typeof data === 'object') {
			super(data.message);
		} else if (Array.isArray(data)) {
			super(JSON.stringify(data));
		} else {
			super(data);
		}

		this.data = data;
		this.status = status;
		this.next = next;
		this.fields = data.invalid || [];
	}
}

export function getMessageBody({ message = null, status, fields }, fallback = 'An error has occurred') {
	if(status === HttpStatus.NOT_ACCEPTABLE && fields) {
		return <div>
			{message && <h4>{message}</h4>}
			The following {fields.length === 1 ? 'field was' : 'fields were'} invalid:
			<ul className="mb-0">
				{fields.map((field, i) => <li key={i}>{field}</li>)}
			</ul>
		</div>;
	}
	return fallback;
}
