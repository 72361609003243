import { Form } from 'react-bootstrap';
import { loadPersons, loadTrainings } from '../../utils';
import AsyncSelect from 'react-select/async';

function getSelectValue(value, label) {
	let result = null;
	if(value && label) {
		result = { value, label };
	}
	return result;
}

function CompliancyFilter(values, handleSetEvent, handleSetValues, persons, trainings) {
	return <>
		{persons && <Form.Group className="col-6">
			<Form.Label>Person*</Form.Label>
			<AsyncSelect
				value={getSelectValue(values.person, values.personName)}
				loadOptions={loadPersons}
				onChange={(opt) => {
					handleSetValues('person', opt?.value || '');
					handleSetValues('personName', opt?.label || '');
				}}
				defaultOptions={persons}
				isClearable
			/>
		</Form.Group>}
		{trainings && <Form.Group className="col-6">
			<Form.Label>Training*</Form.Label>
			<AsyncSelect
				value={getSelectValue(values.training, values.trainingName)}
				loadOptions={loadTrainings}
				onChange={(opt) => {
					handleSetValues('training', opt?.value || '');
					handleSetValues('trainingName', opt?.label || '');
				}}
				defaultOptions={trainings}
				isClearable
			/>
		</Form.Group>}
		<div className="my-2"/>
		<Form.Group className="col-6">
			<Form.Label>Days remaining</Form.Label>
			<Form.Control
				name="remaining"
				value={values.remaining}
				type="text"
				placeholder="..."
				onChange={handleSetEvent}
			/>
		</Form.Group>
		<Form.Group className="col-6">
			<Form.Label>Score</Form.Label>
			<Form.Control
				name="score"
				value={values.score}
				type="text"
				placeholder="..."
				onChange={handleSetEvent}
			/>
		</Form.Group>
		<div className="my-2"/>
		<Form.Group className="col-6">
			<Form.Label>From</Form.Label>
			<Form.Control
				name="from"
				value={values.from}
				type="date"
				onChange={handleSetEvent}
			/>
		</Form.Group>
		<Form.Group className="col">
			<Form.Label>To</Form.Label>
			<Form.Control
				name="to"
				value={values.to}
				type="date"
				onChange={handleSetEvent}
			/>
		</Form.Group>
	</>;
}

export default CompliancyFilter;
