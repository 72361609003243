import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import CompliancyList from '../components/home/CompliancyList';
import ExportBar from '../components/home/ExportBar';
import api from '../api';
import FilterWrapper from '../components/FilterWrapper';
import CompliancyFilter from '../components/home/CompliancyFilter';
import useQuery from '../hooks/useQuery';
import { listToOptions } from '../utils';
import Alert from '../components/alert/Alert';

const defaultInput = {
	person: '',
	personName: '',
	training: '',
	trainingName: '',
	remaining: '',
	score: '',
	from: '',
	to: ''
};

export default function Home() {
	const [lists, setLists] = useState(null);
	const [persons, setPersons] = useState(null);
	const [trainings, setTrainings] = useState(null);
	const [error, setError] = useState(null);
	const [params] = useQuery();

	useEffect(() => {
		setLists(null);
		api.compliancy.getLists(params).then(setLists).catch(setError);
	}, [params]);

	useEffect(() => {
		api.person.getPersons({ max: 50 }).then(listToOptions).then(setPersons).catch(setError);
		api.training.getTrainings({ max: 50 }).then(listToOptions).then(setTrainings).catch(setError);
	}, []);

	return <>
		<Container fluid="lg">
			{error && <Alert message={error}/>}
			<ExportBar query={params}/>
			<FilterWrapper
				defaultInput={defaultInput}
				render={(...p) => CompliancyFilter(...p, persons, trainings)}
				disabled={lists === null}
			/>
		</Container>
		<Container fluid>
			<div className="row">
				<CompliancyList title="Compliant" list={lists?.compliant}/>
				<CompliancyList title="Non-Compliant" list={lists?.['non-compliant']}/>
			</div>
		</Container>
	</>;
}
