import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CheckBox({ checked=false, ...props }) {
	return <FontAwesomeIcon
		icon={`fa-regular fa-${checked? 'square-check' : 'square'}`}
		color="black"
		size="lg"
		{...props}
	/>;
}

export default CheckBox;
