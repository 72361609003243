import axios from 'axios';
import { kick } from '../hooks/account';
import APIError from './APIError';

export const URL = '/api';
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const ILLEGAL = [UNAUTHORIZED, FORBIDDEN];

const instance = axios.create({
	baseURL: URL
});

instance.interceptors.response.use(res => res, err => {
	if(err.response) {
		if(ILLEGAL.includes(err.response.status)) {
			kick();
		}
		return Promise.reject(new APIError(
			err.response.data,
			err.response.status
		));
	}
	return Promise.reject(err);
});

export function getData(res) {
	return res.data;
}

export default instance;
