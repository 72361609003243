// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client, { getData } from '../client';


export default {
	createTraining(training) {
		return client.post('/training', training);
	},
	getTrainings(query) {
		return client.get('/trainings', { params: query }).then(getData);
	},
	getTraining(id) {
		return client.get(`/training/${encodeURIComponent(id)}`).then(getData);
	},
	updateTraining(id, newTraining) {
		return client.put(`/training/${encodeURIComponent(id)}`, newTraining);
	},
	deleteTraining(id) {
		return client.delete(`/training/${encodeURIComponent(id)}`);
	}
};
