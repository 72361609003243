class DummyStorage {
	constructor() {
		this.data = {};
	}

	clear() {
		this.data = {};
	}

	getItem(key) {
		return this.data[key];
	}

	key(index) {
		return Object.keys(this.data)[index];
	}

	removeItem(key) {
		delete this.data[key];
	}

	setItem(key, value) {
		this[key] = `${value}`;
	}
}

function getStorage(type) {
	try {
		const storage = window[type];
		storage.setItem('_test_', '_test_');
		storage.removeItem('_test_');
		return storage;
	} catch(e) {
		return new DummyStorage();
	}
}

export const session = getStorage('sessionStorage');
export const local = getStorage('localStorage');
export const canSignIn = !(session instanceof DummyStorage);
