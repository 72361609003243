import React, { useState, createContext, useContext } from 'react';
import { session } from '../storage';
import FlashMessages from '../messages/FlashMessages';

const EMPTY = {
	account: {},
	checking: true,
	loggedIn: false,
	roles: {}
};

const Context = createContext(EMPTY);

function getInitialState() {
	if((session.getItem('loggedIn') || '0') === '1') {
		return { ...EMPTY, loggedIn: true };
	}
	return EMPTY;
}

let globalState;

export function AccountProvider({ children }) {
	const [state, setState] = useState(getInitialState);
	state._setState = setState;
	globalState = state;
	return <Context.Provider value={state} children={children} />;
}

export function useAccount() {
	const { account, checking, loggedIn, roles, _setState } = useContext(Context);
	return {
		account, checking, loggedIn, roles,
		login(acc) {
			session.setItem('loggedIn', '1');
			_setState({
				account: acc,
				loggedIn: true,
				roles: (acc.roles || []).reduce((o, r) => {
					o[r] = true;
					return o;
				}, { ...EMPTY.roles })
			});
		},
		logout() {
			session.setItem('loggedIn', '0');
			_setState(EMPTY);
		},
		hasRole(role) {
			return account.admin || account.roles?.includes(role);
		},
		doneChecking() {
			EMPTY.checking = false;
			_setState(old => ({...old, checking: false}));
		}
	};
}

export function kick() {
	if(globalState?.loggedIn) {
		FlashMessages.set('auth', 'Your session has expired', { color: 'danger' });
		session.setItem('loggedIn', '0');
		globalState._setState(EMPTY);
		window.location = '/';
	}
}
