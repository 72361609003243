import { Button, Form, Container } from 'react-bootstrap';

import Alert from './alert/Alert';

export default function LoginForm({ lastErrorMessage, submitButtonDisabled, onSubmit, username, password, setField }) {
	return (
		<Container className='d-flex flex-column align-items-center justify-content-center vh-100'>
			<div className="col-4 p-5 text-center bg-white rounded shadow">
				<p className="sign-in mb-4">Sign in</p>

				{lastErrorMessage && <Alert message={lastErrorMessage} />}

				<Form onSubmit={onSubmit}>
					<Form.Group className='mb-3'>
						<Form.Control className="border" name='username' placeholder='username' value={username} autoFocus onChange={setField} />
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Control type='password' name='password' placeholder='password' value={password} onChange={setField} />
					</Form.Group>

					<Button color="primary" className="w-50" type='submit' disabled={submitButtonDisabled || !username || !password}>Login</Button>
				</Form>
			</div>
		</Container>
	);
}
