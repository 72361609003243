// //Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import client, { getData } from '../client';


export default {
	getAccounts(query) {
		return client.get('/accounts', { params: query }).then(getData);
	},
	getAccount(username) {
		return client.get(`/account/${encodeURIComponent(username)}`).then(getData);
	},
	updateAccount(account) {
		return client.put('/account', account);
	},
	updatePassword({ password, oldPassword }) {
		return client.put('/account/password', { password, oldPassword });
	},
	deleteAccount(username) {
		return client.delete(`/account/${encodeURIComponent(username)}`);
	}
};
