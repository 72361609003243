//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
import HttpStatus from 'http-status-codes';
import { getMessageBody } from '../api/APIError';

export function createMessage(message, data) {
	return { message, data };
}

export function dangerMessage(message) {
	return createMessage(message, { color: 'danger' });
}

export function successMessage(message) {
	return createMessage(message, { color: 'success' });
}

export function warningMessage(message) {
	return createMessage(message, { color: 'warning' });
}

function getFallback(err) {
	if(Number.isInteger(err.status)) {
		return err.message;
	}
}

export function errorMessage(err, fallback = getFallback(err)) {
	const message = getMessageBody(err, fallback);
	if(err.status < HttpStatus.BAD_REQUEST) {
		return createMessage(message);
	}
	return dangerMessage(message);
}

export default {
	create: createMessage,
	danger: dangerMessage,
	success: successMessage,
	warning: warningMessage,
	ofError: errorMessage
};
