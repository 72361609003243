import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import Alert from './alert/Alert';
import Spinner from './Spinner';

const SUCCESS_DURATION = 5000;

const SUCCESS_MESSAGE = {
	message: 'Save successful'
};

function CrudForm({ defaultValues, validate, submit, render }) {
	const [values, setValues] = useState(defaultValues);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		setError(null);
		setSubmitting(true);
		setSuccess(false);
		submit(values, setValues)
			.then(() => {
				setSuccess(true);
				setTimeout(() => {
					setSuccess(false);
				}, SUCCESS_DURATION);
			})
			.catch(setError)
			.finally(() => {
				setSubmitting(false);
			});
	}

	function handleSetValues(prop, newValue) {
		setValues(old => ({...old, [prop]: newValue}));
	}
	function setEventValues(e) {
		handleSetValues(e.target.name, e.target.value);
	}
	return <Form className="bg-white rounded shadow-sm p-4" onSubmit={handleSubmit}>
		{render(values, setEventValues, handleSetValues)}
		{error && <Alert className="mb-2" message={error}/>}
		{success && <Alert className="mb-2" message={SUCCESS_MESSAGE} variant="success"/>}
		{submitting && <Spinner color="black" size="lg"/>}
		{!submitting && <Button
			type="submit"
			variant='primary'
			disabled={!validate(values)}
		>Save</Button>}
	</Form>;
}

export default function FormWrapper({ defaultValues, ...props }) {
	if(!defaultValues) {
		return <Spinner color="black" size="lg"/>;
	}
	return <CrudForm defaultValues={defaultValues} {...props}/>;
}
