import { useState } from 'react';
import DeleteModal from './DeleteModal';
import Alert from './alert/Alert';

export default function TableWrapper({
	className='bg-white p-4 rounded shadow-sm', render, deleteCB, error
}) {
	const [toDelete, setToDelete] = useState(null);
	const handleSetToDelete = (p) => () => setToDelete(p);
	function handleCloseDelete() {
		setToDelete(null);
	}
	return <div className={className}>
		<DeleteModal toDelete={toDelete} deleteCB={deleteCB} close={handleCloseDelete} />
		{error && <Alert message={error}/>}
		{render(handleSetToDelete)}
	</div>;
}
