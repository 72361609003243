import { Table } from 'react-bootstrap';
import Spinner from '../Spinner';
import TableWrapper from '../TableWrapper';
import { useAccount } from '../../hooks/account';
import TableControlButtons from '../TableControlButtons';
import THSort from '../THSort';
import { formatDate } from '../../utils';

export default function PersonTable({ persons, deletePerson, text, error }) {
	const { hasRole } = useAccount();
	const canWritePersons = hasRole('writePersons');
	const anyEndDate = persons?.some(p => p.endDate);
	let query = '';
	if(text) {
		query = `?text=${text}&files=`;
	}
	return <TableWrapper
		error={error}
		deleteCB={deletePerson}
		render={(handleDelete) => <>
			<Table striped>
				<thead>
					<tr>
						<th>Name</th>
						<th>Function</th>
						<THSort value="email">Email</THSort>
						<THSort value="startDate" defaultSort>Started</THSort>
						{anyEndDate && <THSort value="endDate">Ended</THSort>}
						{canWritePersons && <>
							<th/>
							<th/>
						</>}
					</tr>
				</thead>
				<tbody>
					{persons?.map(p => <tr key={p._id}>
						<td>{p.name}</td>
						<td>{p.function}</td>
						<td>{p.email}</td>
						<td>{formatDate(p.startDate)}</td>
						{p.endDate && <td>{formatDate(p.endDate)}</td>}
						{!p.endDate && anyEndDate && <td/>}
						<TableControlButtons
							hasRole={canWritePersons}
							editTo={`/person/${encodeURIComponent(p._id)}${query}${p.files.join('&files=')}`}
							onDelete={handleDelete({ id: p._id, name: p.name })}
						/>
					</tr>)}
				</tbody>
			</Table>
			{!persons && <Spinner size="lg"/>}
		</>}
	/>;
}
